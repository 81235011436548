<template>
  <b-modal
    :id="'e-add-seller'"
    ok-only
    centered
    title="Effectuez une nouvelle vente"
    @ok="addSeller"
  >
    <b-form>
      <!-- Libellé -->
      <b-form-group label="Libellé">
        <template #label>
          Pour quel client ? <span class="text-danger">*</span>
        </template>

        <v-select
          v-model="newSeller.client"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="nom"
          :options="clients"
          placeholder="Choisir un client"
        >
          <template v-slot:option="option">
            {{ option.nom }} {{ option.prenoms }}
          </template>
        </v-select>
        <span
          class="text-danger"
          style="font-size: 12px"
          v-if="errorInput.path === 'libelle'"
        >
          {{ errorInput.message }}
        </span>
      </b-form-group>

      <!-- Prix de vente -->
      <b-form-group label="Prix de vente">
        <template #label> Prix de vente <span class="text-danger">*</span> </template>

        <b-form-input
          id="libelle"
          v-model="newSeller.prix_vente"
          name="libelle"
          placeholder="Prix de vente"
          type="number"
          min="0"
        />
        <span
          class="text-danger"
          style="font-size: 12px"
          v-if="errorInput.path === 'libelle'"
        >
          {{ errorInput.message }}
        </span>
      </b-form-group>

      <!-- Prix de vente -->
      <b-form-group label="Quantité">
        <template #label> Quantité <span class="text-danger">*</span> </template>

        <b-form-input
          id="libelle"
          v-model="newSeller.qte"
          name="libelle"
          placeholder="Quantité de vente"
          type="number"
          min="0"
        />
        <span
          class="text-danger"
          style="font-size: 12px"
          v-if="errorInput.path === 'libelle'"
        >
          {{ errorInput.message }}
        </span>
      </b-form-group>

      <b-form-group label="Libellé">
        <template #label> Appliquer une taxe </template>

        <v-select
          v-model="newSeller.taxe"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="libelle"
          :options="taxes"
          placeholder="Choisir une taxe pour l'article"
        />
        <!-- <template v-slot:option="option">
            {{ option.nom }} {{ option.prenoms }}
          </template> -->
        <span
          class="text-danger"
          style="font-size: 12px"
          v-if="errorInput.path === 'libelle'"
        >
          {{ errorInput.message }}
        </span>
      </b-form-group>
    </b-form>

    <template #modal-footer="{}">
      <b-button
        :disabled="state.loading === true ? true : false"
        variant="primary"
        @click.prevent="addSeller"
      >
        <span v-if="state.loading === false">Ajouter</span>
        <b-spinner v-if="state.loading === true" label="Spinning"></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  VBModal,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
import { onMounted, reactive, ref, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import URL from "../request";
import moment from "moment";
import { qCryptJson, qDecrypted } from "@/services/qeCrypt";
import { toast_destory, toast_error, toast_orign } from "@/utils/qToast";
import qDeviseUtils from "@/utils/qDeviseUtils";
import { qSellerValidator } from "@/utils/validators/sellerValidate";

export default {
  components: {
    BInputGroupPrepend,
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VBModal,

    vSelect,
  },

  props: ["stock", "stocks"],

  setup(props, { root }) {
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
      is_Statistics: false,
    });

    onMounted(async () => {
      await getClients();
      await getTaxes();
    });

    /**
     *
     *
     *
     */

    const errorInput = {
      path: "",
      message: "",
    };
    const taxes = ref([]);
    const sellerData = ref({});
    const clients = ref([]);
    const newSeller = reactive({
      client: "",
      prix_vente: props.stock.prix_vente,
      qte: 1,
      taxe: "",
    });

    const addSeller = async () => {
      console.log(props.stock);
      try {
        state.loading = true;
        const { isValid, EI } = await qSellerValidator(newSeller, props.stock);
        if (!isValid) {
          state.loading = false;
          errorInput.path = EI.path;
          errorInput.message = EI.message;
        } else {
          const { data } = await axios.post(URL.SELLER_ADD, {
            client: newSeller.client.id,
            article_id: [props.stock.id],
            prix_vente: [newSeller.prix_vente],
            qte: [newSeller.qte],
            prix_total: [newSeller.prix_vente * newSeller.qte],
            taxe_id: newSeller.taxe.id,
          });
          if (data) {
            root.$bvModal.hide("e-add-seller");
            toast_orign(
              root,
              props.stock.libelle,
              "ShoppingCartIcon",
              "Articles vendu avec sucess",
              "Success",
              "top-right"
            );

            props.stocks.forEach((item) => {
              if (item.id === props.stock.id) {
                item.qte_restante = item.qte_restante - newSeller.qte;
              }
            });

            state.loading = false;
            newSeller.client = "";
            newSeller.prix_vente = "";
            newSeller.qte = "";
          }
        }
      } catch (error) {
        state.loading = false;
        console.log(error);
      }
    };

    /**
     * @param {*} e
     */
    const getTaxes = async () => {
      try {
        const { data } = await axios.get(URL.TAXE_LIST);
        console.log(data);
        if (data.taxes) {
          taxes.value = data.taxes;
        }
      } catch (error) {
        console.log(error);
      }
    };

    /**
     * @param {*} e
     */
    const getClients = async () => {
      try {
        const { data } = await axios.get(URL.CLIENT_LIST);
        console.log(data);
        if (data[0]) {
          clients.value = data[0];
        }
      } catch (error) {
        console.log(error);
      }
    };

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    const updateStock = (id) => {};

    return {
      state,
      //Seller
      newSeller,
      clients,
      sellerData,
      errorInput,
      taxes,
      addSeller,
      formatter,
    };
  },
};
</script>
