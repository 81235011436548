export const qSellerValidator = async (request, stock) => {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const digit = /^\d+$/;
    let errorInput = {
        error: false,
        path : '',
        message : ''
    }

    let isValid = true
    if (request.client === "") {
        errorInput.path = "client";
        errorInput.message = "Veillez entrer un libellé";
        isValid = false;
      } else if (request.prix_vente === "" ) {
        errorInput.path = "prix_vente";
        errorInput.message = "Le prix de vente est obligatoire";
        isValid = false;
      } else if (request.qte === "" ) {
        errorInput.path = "qte";
        errorInput.message = "Le prix de vente est obligatoire";
        isValid = false;
      } else if (request.qte > stock.qte_restante ) {
        errorInput.path = "qte";
        errorInput.message = `Operation invalid : quanité rest: ${stock.qte_restante} `;
        isValid = false;
      } 
      

      return {isValid : isValid, EI: errorInput}
}

