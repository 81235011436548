<template>
  <b-card no-body>
    <div class="p-2" v-if="state.is_Statistics">
      <app-echart-bar class="w-100" :option-data="statisticsData" />
    </div>

    <b-row class="px-2">
      <!-- Per Page -->

      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center flex-wrap justify-content-start mb-1 mb-md-0 mt-1"
      >
        <label>Entrées</label>

        <v-select
          v-model="state.perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block ml-50 mr-1"
        />

        <b-button
          variant="primary"
          @click="modeModal = 'e-add-new-article'"
          v-b-modal.e-add-new-article
        >
          <feather-icon icon="PlusIcon" class="mx-auto" />
          Ajouter un stock
        </b-button>

        <b-button class="ml-1" variant="primary" @click="isActivedStatisc">
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
          Statistiques
        </b-button>
      </b-col>

      <!-- Search -->

      <b-col cols="12" md="4" class="mt-1">
        <div class="d-flex align-items-center justify-content-end">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="state.filtre"
              class="d-inline-block mr-1"
              placeholder="Rechercher par : nom du stock, type, prix valeur, date..."
            />
          </b-input-group>
        </div>
      </b-col>
    </b-row>

    <q-loader-table
      :success="state.success"
      :empty="state.empty"
      :warring="state.warring"
    />

    <b-table
      hover
      responsive
      primary-key="id"
      :per-page="state.perPage"
      :current-page="state.currentPage"
      :items="stocks"
      :fields="tableColumns"
      :filter="state.filter"
      show-empty
      empty-text="Aucun article enregistré"
      class="bg-white text-left"
      v-if="state.success === true"
    >
      <template #cell(libelle)="data">
        <section class="d-flex align-items-center">
          <div class="">
            <img style="width: 32px; height: 32px" :src="data.item.media" alt="" />
          </div>

          <div class="ml-50">
            <div class="d-flex flex-column">
              <span>{{ data.item.libelle }}</span>
            </div>
            <div style="font-size: 12px">
              <feather-icon icon="ChevronRightIcon" size="12" />
              {{
                data.item.categorie.libelle
                  ? data.item.categorie.libelle
                  : data.item.categorie
              }}
            </div>
          </div>
        </section>
      </template>

      <template #cell(prix_vente)="data">
        <span>{{ formatter(parseInt(data.item.prix_vente)) }}</span>
      </template>

      <!-- Bouton d'action -->

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap py-1">
          <b-button
            :disabled="data.item.qte_restante <= 0 ? true : false"
            v-b-modal.e-add-seller
            size="sm"
            variant="warning"
            :data="data.item"
            class=""
            @click="qGetUidStock(data.item)"
          >
            <feather-icon icon="ShoppingCartIcon" class="mr-50" size="14" />
            <span style="font-size: 12px">Vendre</span>
          </b-button>

          <!-- <feather-icon
            @click="updateStock(data.item)"
            icon="Edit3Icon"
            size="16"
            class="cursor-pointer"
          /> -->

          <feather-icon
            @click="viewStock(data.item)"
            icon="EyeIcon"
            size="16"
            class="mx-1 cursor-pointer"
          />

          <feather-icon
            @click="destoryStock(data.item.id)"
            icon="TrashIcon"
            class="cursor-pointer"
            size="16"
          />

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
            disabled
            class="hidden"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item @click="editFac(data.item.id)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item> -->
            <b-dropdown-item disabled>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Supprimer</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <!-- Les boutons de pagination -->

    <div class="mx-2 mb-2 pb-1">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"></span>
        </b-col>

        <!-- Pagination -->

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="state.currentPage"
            :total-rows="stocks.length"
            :per-page="state.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    {{ __OnRealTime }}

    <e-add-sales :stock="getUidStock" :stocks="stocks" />

    <e-add-articles
      :optionType="['Produit']"
      :categories="categories"
      :articles="stocks"
    />
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  VBModal,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
import { onMounted, reactive, ref, computed } from "@vue/composition-api";
import QLoaderTable from "@/components/__partials/loaders/qLoaderTable.vue";
import Article from "../dashboard/article/article.vue";
import vSelect from "vue-select";
import URL from "../request";
import moment from "moment";
import EAddArticles from "@/components/__partials/articles/eAddArticles.vue";
import { qCryptJson, qDecrypted } from "@/services/qeCrypt";
import VueApexCharts from "vue-apexcharts";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import { toast_destory, toast_error } from "@/utils/qToast";
import qDeviseUtils from "@/utils/qDeviseUtils";
import EAddSales from "./eAddSales.vue";

export default {
  components: {
    VueApexCharts,
    AppEchartBar,
    BInputGroupPrepend,
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VBModal,
    QLoaderTable,
    vSelect,
    Article,
    EAddArticles,
    EAddSales,
  },
  setup(props, { root }) {
    // const providerArticle = Article.setup(props, { root });
    const stocks = ref([]);
    const state = reactive({
      perPage: 30,
      currentPage: 1,
      filter: "",
      success: false,
      empty: false,
      warring: false,
      loading: false,
      is_Statistics: false,
    });
    const perPageOptions = ["30", "50", "100", "200", "500"];
    const GIVE = ref(false);
    const tableColumns = reactive([
      {
        key: "libelle",
        label: "Libellé",
      },
      {
        key: "prix_vente",
        label: "Prix de vente",
      },
      {
        key: "qte_restante",
        label: "Quantité",
      },
      {
        key: "created_at",
        label: "Date de création",
      },
      {
        key: "actions",
        label: "Actions",
      },
    ]);
    const statisticsData = reactive({
      xAxis: [
        {
          type: "category",
          data: [],
        },
      ],
      yAxis: [
        {
          type: "value",
          data: [],
        },
      ],
      grid: {
        left: "8%",
        right: "0%",
        bottom: "5%",
      },
      series: [
        {
          name: "Quantité",
          type: "bar",
          stack: "advertising",
          data: [],
          barMaxWidth: "5%",
          barMinWidth: "5%",
        },
        {
          name: "Montant Total",
          type: "bar",
          stack: "advertising",
          data: [],
          barMaxWidth: "5%",
          barMinWidth: "5%",
          itemStyle: {
            barBorderRadius: [10, 10, 0, 0],
          },
        },
      ],
    });

    const categories = ref([]);
    const getUidStock = ref({});

    const qGetUidStock = (item) => {
      getUidStock.value = item;
    };

    onMounted(async () => {
      await listStocks();
      await getCategory();
    });

    const __OnRealTime = computed(() => {
      if (GIVE.value === true) {
        stocks.value.length === 0
          ? ((state.empty = true), (state.success = false))
          : ((state.success = true), (state.empty = false));
      }
    });

    // Get all category
    const getCategory = async () => {
      try {
        const { data } = await axios.get(URL.CATEGORIE_LIST);
        if (data) {
          categories.value = data;
          GIVE.value = true;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const listStocks = async () => {
      try {
        const { data } = await axios.get(URL.INVENTORY_LIST);
        console.log(data);
        if (data.stock) {
          GIVE.value = true;

          const statisticsData_x = [];
          const statisticsData_y = [];
          const dataSeries_quantite = [];
          const dataSeries_total = [];
          const dataSeries_libelle = [];

          for (let i = 0; i < data.stock.length; i++) {
            const stock = data.stock[i];
            stock.created_at = moment(stock.created_at).format("DD-MM-YYYY");
            stock.media =
              stock.media.length !== 0
                ? stock.media[0].original_url
                : "https://via.placeholder.com/150/4B0082/FFFFFF/?text=Ediqia";
            stock.categorie = {
              id: stock.parametre.type_parametre_id,
              libelle: stock.parametre.libelle,
            };
          }

          for (let i = 0; i < data.pivot.length; i++) {
            const sale = data.pivot[i];

            dataSeries_libelle.push(sale.code_facture);
            statisticsData_x.push(`${sale.libelle}`);
            statisticsData_y.push(`${sale.qte_un_article}`);
            dataSeries_quantite.push(`${sale.qte_un_article}`);
            dataSeries_total.push(sale.prix_vente * sale.qte_un_article);
          }

          statisticsData.xAxis[0].data = statisticsData_x;
          statisticsData.yAxis[0].data = statisticsData_y;
          statisticsData.series[0].data = dataSeries_quantite;
          statisticsData.series[1].data = dataSeries_total;
          stocks.value = data.stock;
        }
      } catch (error) {
        state.success = false;
        state.warring = true;
        console.log(error);
      }
    };

    /**
     *
     *
     *
     */
    const viewStock = async (detail) => {
      // console.log("detail", [detail]);
      localStorage.setItem("stockDetails", qCryptJson(detail));
      root.$router.push({ name: "gsMyStockDetails" });
    };

    const destoryStock = async (id) => {
      root
        .$swal({
          title: `Êtes vous sûr !?`,

          text: "Aucun retour en arriére ne sera posible",

          icon: "warning",

          showCancelButton: true,

          confirmButtonText: "Oui",

          customClass: {
            confirmButton: "btn btn-primary",

            cancelButton: "btn btn-outline-danger ml-1",
          },

          buttonsStyling: false,
        })
        .then(async (response) => {
          if (response.isConfirmed === true) {
            try {
              const { data } = await axios.post(URL.ARTICLE_DESTROY, {
                id: id,
              });
              if (data) {
                stocks.value = stocks.value.filter((art) => {
                  return art.id !== id;
                });
                toast_destory(
                  root,
                  "success",
                  "top-right",
                  `Article supprimer avec succès !`
                );
                stocks.value.length === 0 ? (state.empty = true) : (state.success = true);
              }
            } catch (error) {
              toast_error(
                root,
                "danger",
                "top-right",
                `Oups, un erreur est survenu veillez ressayer...`
              );
            }
          }
        });
    };

    const isActivedStatisc = () => {
      if (state.is_Statistics === true) {
        state.is_Statistics = false;
      } else {
        state.is_Statistics = true;
      }
    };

    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    const updateStock = (id) => {};

    return {
      state,
      stocks,
      tableColumns,
      perPageOptions,
      __OnRealTime,
      destoryStock,
      updateStock,
      categories,
      viewStock,
      statisticsData,
      isActivedStatisc,

      //Seller
      formatter,
      qGetUidStock,
      getUidStock,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
